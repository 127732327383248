<template>
  <div>
    <b-modal no-close-on-backdrop :id="id" size="lg" :title="`${this.$route.params.maintenanceRecurringId ? 'Edit' : 'Create'} Recurring Maintenance`" @hidden="closeModal">
      <b-row>
        <b-col cols="12" v-if="!loading && error">
          <b-alert variant="danger">{{ error }}</b-alert>
        </b-col>
        <b-col class="py-4 text-center" cols="12" v-if="loading">
          <b-spinner variant="primary"></b-spinner>
        </b-col>
      </b-row>
      <b-row v-if="!loading && !error">
        <b-col cols="12">
          <b-row>
            <b-col cols="12">
              <b-form-group label="Property" :disabled="editedRecurringMaintenance.customer_id" :invalid-feedback="getValidationMessage('property_id')" :state="!validationErrors.property_id">
                <property-search @delete="editedRecurringMaintenance.property_id = null" show-delete v-model="editedRecurringMaintenance.property_id" />
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="12" md="6">
              <b-form-group label="Name">
                <b-form-input type="text" v-model="editedRecurringMaintenance.name"/>
              </b-form-group>
            </b-col>
            <b-col cols="12" md="6">
              <b-form-group label="User">
                <user-search type="maintenance" v-model="editedRecurringMaintenance.user_id" />
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="6">
              <b-form-group label="Occurrence">
                <b-form-select v-model="editedRecurringMaintenance.occurrence_type" :options="['Weekly', 'Monthly', 'Yearly']" />
              </b-form-group>
            </b-col>
            <b-col cols="6">
              <b-form-group label="Day Of Week" v-if="editedRecurringMaintenance.occurrence_type == 'Weekly'">
                <b-form-select v-model="editedRecurringMaintenance.occurrence_time" :options="[{value: 0, text: 'Sunday'}, {value: 1, text: 'Monday'}, {value: 2, text: 'Tuesday'}, {value: 3, text: 'Wednesday'}, {value: 4, text: 'Thursday'}, {value: 5, text: 'Friday'}, {value: 6, text: 'Saturday'}]" />
              </b-form-group>
              <b-form-group label="Day Of Month (1-31)" v-else-if="editedRecurringMaintenance.occurrence_type == 'Monthly'">
                <b-form-input type="numeric" v-model="editedRecurringMaintenance.occurrence_time" />
              </b-form-group>
              <b-form-group label="Day Of Year (1-365)" v-else-if="editedRecurringMaintenance.occurrence_type == 'Yearly'">
                <b-form-input type="numeric" v-model="editedRecurringMaintenance.occurrence_time" />
              </b-form-group>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
      <template #modal-footer>
        <b-button @click.prevent="closeModal" variant="light">Close</b-button>
        <b-button @click.prevent="saveRecurringMaintenance" variant="primary">
          <b-spinner class="mx-4" variant="light" small v-if="saving"/>
          <span v-if="!saving">{{ $route.params.recurringMaintenanceId ? 'Update' : 'Create' }}</span>
        </b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import handleErrors from '../../mixins/handleErrors';
import PropertySearch from "@/components/PropertySearch.vue";
import UserSearch from "@/components/UserSearch.vue";

export default {
  components: {UserSearch, PropertySearch,},
  emits: ['hidden'],
  mixins: [handleErrors],
  data() {
    return {
      validationErrors: {},
      genericError: undefined,
      editedRecurringMaintenance: {
        id: '',
        property_id: '',
        user_id: null,
        name: '',
        occurrence_type: '',
        occurrence_time: 0
      },
      uploading: false,
      loading: false,
      saving: false,
      error: ''
    };
  },
  props: {
    recurringMaintenance: Object,
    id: String,
  },
  async mounted() {
    if (this.$route.params.recurringMaintenanceId
      && this.recurringMaintenance
    ) {
      this.loading = true;

      try {
        const recurringMaintenance = await this.fetchSingle(this.$route.params.recurringMaintenanceId);
        this.editedRecurringMaintenance = {
          ...recurringMaintenance
        };
        this.loading = false;
      } catch (err) {
        this.error = "Can not load recurring maintenance. Please try again later.";
        this.loading = false;
      }
    }
  },
  methods: {
    ...mapActions('maintenanceRecurrings', ['fetch', 'fetchSingle', 'updateOrCreate']),
    join(join, arr) {
      if (arr && arr.length) {
        return arr.join(join)
      }

      return ''
    },
    closeModal() {
      this.editedRecurringMaintenance = {};
      this.validationErrors = {};
      if (this.$route.name !== this.$route.meta.parentName) {
        this.$router.push({ name: this.$route.meta.parentName });
      }
      this.$emit('hidden')
    },
    saveRecurringMaintenance() {
      this.saving = true;

      this.updateOrCreate({
        ...this.editedRecurringMaintenance,
      }).then(() => {
        this.saving = false;
        this.closeModal();
        return this.fetch();
      }).catch(err => {
        this.saving = false;
        if (err.response && err.response.status === 422) {
          this.saving = false;
          this.handleValidationErrors(err);
        } else {
          this.saving = false;
          this.genericError = 'There was an error saving just now. Please try again later.';
        }
      });
    },
  },
  watch: {
    recurringMaintenance(value) {
      if (value === undefined) {
        return
      }

      this.editedRecurringMaintenance = {
        ...value
      };
    },
  }
}
</script>

